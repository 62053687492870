import { faCatSpace } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Tooltip } from '@northvolt/tooltip'

import { HeaderTheme } from './types'

const getInitials = (name: string) => {
  return name.split(' ').map((word: string, i: number) => {
    if (i > 2) {
      return ''
    }
    return word[0]
  })
}

const getNameFromEmail = (email: string) =>
  email
    .split('@')[0]
    .split(/[.-]/)
    .map((name: string) => name.charAt(0).toUpperCase() + name.slice(1))
    .join(' ')

export type HeaderMenuItem = {
  type: 'item'
  title: NonNullable<ReactNode>
  icon?: ReactNode
  onClick?: () => void
}

export type HeaderMenuLink = {
  type: 'link'
  title: NonNullable<ReactNode>
  icon?: ReactNode
  link: string
  externalLink?: boolean
}

export type HeaderMenuSection = {
  type: 'section'
  title: string
}

export type HeaderMenuDivider = {
  type: 'divider'
}

export type HeaderMenuOption =
  | HeaderMenuItem
  | HeaderMenuLink
  | HeaderMenuDivider
  | HeaderMenuSection

type User = {
  firstName: string
  lastName: string
  email?: string
  extra: string | null

  /**
   * Self-explanatory
   */
  showCatInsteadOfInitials?: boolean
}

export type ProfileDropDownProps = {
  user: User
  environment: string
  version: string
  menu?: HeaderMenuOption[]
  showNotificationDot?: boolean
  theme: HeaderTheme
}

export function ProfileDropDown({
  environment,
  menu,
  user,
  version,
  showNotificationDot,
  theme,
}: ProfileDropDownProps) {
  return (
    <Menu as="div" className="relative inline-block text-left align-middle">
      <MenuButton className="p-1 -mr-1 group focus:outline-none outline-none">
        <div
          className={clsx(
            'font-semibold tracking-wider rounded-full h-9 w-9 flex items-center justify-center text-white bg-NvTeal-300 transition',
            {
              'before:content-[""] before:block before:absolute before:top-0 before:left-0 before:w-3 before:h-3 before:rounded-xl before:bg-red before:z-10':
                showNotificationDot,
            },
            {
              'group-hover:bg-powerGreen': theme !== 'light',
              'group-hover:bg-NvBlue-300': theme === 'light',
            },
          )}
        >
          {user.showCatInsteadOfInitials && <FontAwesomeIcon icon={faCatSpace} />}
          {user.email && !user.showCatInsteadOfInitials
            ? getInitials(getNameFromEmail(user.email))
            : null}
        </div>
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="z-40 w-80 p-4 absolute right-0 my-5 origin-top-right bg-white rounded-lg shadow-lg border border-solid border-black border-opacity-5 focus:outline-none ">
          <div className="flex mt-2 pb-5 mb-2 border-b border-gray-400">
            <div className="rounded-full h-11 w-11 text-white bg-NvTeal-300 mr-3 text-lg font-semibold tracking-wider flex items-center justify-center">
              {user.showCatInsteadOfInitials && <FontAwesomeIcon icon={faCatSpace} />}
              {user.email && !user.showCatInsteadOfInitials
                ? getInitials(getNameFromEmail(user.email))
                : null}
            </div>
            <Tooltip enabled={user.extra ? true : false} title={user.extra}>
              <div>
                <h2 className="text-lg font-semibold text-black">
                  {user.firstName ? user.firstName.split(' ')[0] : null} {user.lastName}
                </h2>
                <p className="text-base -mt-1 text-gray-700">{user.email}</p>
              </div>
            </Tooltip>
          </div>
          {menu?.map((menuOption, idx) => {
            if (menuOption.type === 'divider') {
              return <div className="h-0.5 bg-gray-100 mt-1 mb-1" key={`divider-${idx}`} />
            } else if (menuOption.type === 'item' || menuOption.type === 'link') {
              const content = (
                <MenuItem key={menuOption.title.toString()}>
                  <button
                    className="group flex font-normal rounded-md items-center w-full px-1 py-2 my-1 text-sm hover:bg-gray-200"
                    onClick={menuOption.type === 'item' ? menuOption.onClick : undefined}
                  >
                    <div className="ml-3 flex">
                      {menuOption.icon}
                      {typeof menuOption.title === 'string' ? (
                        <div className="text-left pl-3 text-base text-black">
                          {menuOption.title}
                        </div>
                      ) : (
                        <div>{menuOption.title}</div>
                      )}
                    </div>
                  </button>
                </MenuItem>
              )
              if (menuOption.type === 'link') {
                if (menuOption.externalLink) {
                  return (
                    <a href={menuOption.link} key={menuOption.title.toString()}>
                      {content}
                    </a>
                  )
                } else {
                  return (
                    <Link key={menuOption.title.toString()} to={menuOption.link}>
                      {content}
                    </Link>
                  )
                }
              }
              return content
            } else if (menuOption.type === 'section') {
              return (
                <MenuItem key={menuOption.title}>
                  <h2 className="text-black text-lg font-semibold text-left pt-2">
                    {menuOption.title}
                  </h2>
                </MenuItem>
              )
            } else return null
          })}
          <h2 className="pt-3 text-sm text-right text-gray-500">
            {version}
            {environment !== 'production' && ` (${environment})`}
          </h2>
        </MenuItems>
      </Transition>
    </Menu>
  )
}
