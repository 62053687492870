import { MenuItem as HLMenuItem } from '@headlessui/react'
import clsx from 'clsx'
import { ElementType, ReactNode } from 'react'

export type MenuItemProps =
  | {
      children: ReactNode
      onClick?: () => void
      className?: string
      disabled?: boolean
      link?: never
      separator?: never
    }
  | {
      children: ReactNode
      link?: string
      className?: string
      disabled?: boolean
      onClick?: never
      separator?: never
    }
  | {
      className?: string
      separator: boolean
      children?: never
      disabled?: never
      label?: never
      link?: never
      onClick?: never
    }

/**
 * Menu items can be rendered as:
 * - a div with onClick handler
 * - an anchor element with a link
 * - a horizontal rule element
 * @param children Passed to anchor and div elements
 * @param className Passed to the Menu.Item component for all cases
 * @param disabled Disables the Menu.Item and prevents the active state of the item, prevents onClick
 * @param onClick click handler for Menu.Item, Only used when link or separator is not passed
 * @param separator When passed and true, renders the Menu.Item as a horizontal rule
 * @param link Passed to the anchor tag as the href attribute
 */
export function MenuItem({
  children,
  className,
  onClick,
  separator,
  link,
  disabled,
}: MenuItemProps) {
  // If separator is true, just render a horizontal rule
  if (separator) {
    return <HLMenuItem as="hr" className={clsx('mx-2', className)} />
  }

  // If link is passed we render the menu item as an anchor element
  // otherwise it is rendered as div element with a possible onClick handler
  const itemProps: {
    as: ElementType
    href?: string
    target?: string
    onClick?: () => void
    disabled?: boolean
  } = link
    ? {
        as: disabled ? 'span' : 'a',
        ...(disabled ? {} : { href: link, target: '__blank' }),
        disabled,
      }
    : {
        as: 'div',
        onClick,
        disabled,
      }

  return (
    <HLMenuItem
      {...itemProps}
      // active is true if the user is selecting this option with the keyboard
      // or hovering it with the mouse
      className={({ focus }: { focus: boolean }) =>
        clsx(
          // make the whole space free for custom children which includes either onClick or link
          { 'whitespace-nowrap text-left py-2.5 px-4 w-full flex items-center': onClick || link },
          {
            'cursor-pointer': !itemProps.disabled,
            'cursor-not-allowed opacity-50': itemProps.disabled,
          },
          {
            'bg-white': !focus,
            'bg-gray-100': focus,
          },
          className,
        )
      }
    >
      {children}
    </HLMenuItem>
  )
}
