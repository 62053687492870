import { ComponentProps, ReactNode, createContext, useContext, useMemo } from 'react'
import { SWRConfig, useSWRConfig } from 'swr'

import { abortSignalTimeoutPolyfill } from '@northvolt/polyfills'

import { GqlErrorResponse, NvGqlNetworkError, NvGqlResponseError } from './NvGqlError'
import { deserializeSwrKey, keyMatchesQuery } from './swrKey'
import { GqlFetcher, GqlFetcherResponse, OverrideHeaders, QueryType, VariablesType } from './types'

abortSignalTimeoutPolyfill()

export type GqlContextValue = {
  gqlSwrFetcher: <Data extends Record<string, any> = Record<string, any>>(
    args: string,
  ) => Promise<Data>
  gqlFetcher: <Data extends Record<string, any> = Record<string, any>>(
    query: string,
    variables: VariablesType,
  ) => Promise<Data>
  /**
   * invalidates the SWR cache for all active cache entries that match the provided query
   * forcing refetching of said queries
   * if variables is provided only the queries that match those variables will be invalidated
   */
  revalidateQuery(query: QueryType, variables?: VariablesType): Promise<void>
}

function getOverrideHeaders(): OverrideHeaders | null {
  const ApiKey = localStorage.getItem('apiKey')
  const Integration = localStorage.getItem('integration')

  if (ApiKey && Integration) {
    return {
      ApiKey,
      Integration,
    }
  }

  return null
}

const GqlContext = createContext<GqlContextValue | undefined>(undefined)

const defaultGqlFetcher: GqlFetcher = async (
  query: string,
  variables: VariablesType,
  apiUrl: string,
  token: string,
) => {
  let data: GqlFetcherResponse['data'] = null
  let errors: GqlFetcherResponse['errors'] = []
  let response: Response | null = null

  // check for override headers in local storage. This can be useful for some
  // specific use cases where we want to access the API with static tokens by
  // manually adding override headers as specific named localStorage entries.
  const overrideHeaders = getOverrideHeaders()

  let customHeaders = {}
  if (overrideHeaders) {
    customHeaders = overrideHeaders
  } else if (token) {
    customHeaders = {
      authorization: `Bearer ${token}`,
    }
  }

  // inject graphql API override as a header instruction if available
  const toggleDatabricks = localStorage.getItem('toggleDatabricks')
  if (toggleDatabricks) {
    customHeaders = {
      ...customHeaders,
      'x-toggle-databricks': toggleDatabricks,
    }
  }

  try {
    response = await fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables,
      }),
      headers: {
        ...customHeaders,
        accept: '*/*',
        'content-type': 'application/json',
      },
      signal: AbortSignal.timeout(20000),
    })
    if (!response.ok) {
      errors.push(new NvGqlNetworkError(response.status, null))
    } else {
      try {
        const responseBody = await response.json()
        if (responseBody != null) {
          data = responseBody.data ?? null
          if (responseBody.errors != null) {
            // graphql api errors
            errors = [
              ...errors,
              ...responseBody.errors.map((gqlError: GqlErrorResponse) => {
                return new NvGqlResponseError(gqlError)
              }),
            ]
          }
        }
      } catch (e) {
        errors.push(new NvGqlNetworkError('invalid-body', e))
      }
    }
  } catch (e) {
    if (e instanceof Error && e.name === 'TimeoutError') {
      // see:
      // https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/timeout#examples
      errors.push(new NvGqlNetworkError('timeout', e))
    } else {
      errors.push(new NvGqlNetworkError('unknown', e))
    }
  }

  return { data, errors }
}

type GqlProviderProps = {
  apiUrl: string
  getToken: () => Promise<string> | string
  customGqlFetcher?: GqlFetcher
  onError?: (errors: (Error | Record<string, any>)[]) => void
  children: ReactNode
}

function GqlProviderWithSwr({
  apiUrl,
  getToken,
  customGqlFetcher,
  onError,
  children,
}: GqlProviderProps) {
  const { cache, mutate } = useSWRConfig()

  const providerValue = useMemo<GqlContextValue>(() => {
    const onErrorCb =
      onError ??
      ((errors) => {
        errors.forEach((err) => {
          console.error(err)
          // TODO sentry capture exception here
        })
      })

    const gqlFetcher = async (query: string, variables: VariablesType): Promise<any> => {
      let response: GqlFetcherResponse = {
        data: null,
        errors: [],
      }
      const token = await getToken()
      if (customGqlFetcher != null) {
        response = await customGqlFetcher(query, variables, apiUrl, token)
      } else {
        response = await defaultGqlFetcher(query, variables, apiUrl, token)
      }
      const { data, errors } = response

      if (errors.length > 0) {
        onErrorCb(errors)
        if (data == null) {
          // if we get no data at all and have errors then we want to trigger the React error boundary
          // if we get data AND errors we don't throw the errors
          throw errors
        }
      }

      return data
    }

    return {
      gqlFetcher,
      gqlSwrFetcher: async (key: string): Promise<any> => {
        const { query, variables } = deserializeSwrKey(key)
        return gqlFetcher(query, variables)
      },
      async revalidateQuery(query: QueryType, variables?: VariablesType) {
        const promises = []
        const queryStr = query.toString()
        for (const key of cache.keys()) {
          if (keyMatchesQuery(key, queryStr, variables)) {
            promises.push(mutate(key, undefined, true))
          }
        }
        await Promise.all(promises)
      },
    }
  }, [apiUrl, getToken, customGqlFetcher, onError, cache, mutate])

  return <GqlContext.Provider value={providerValue}>{children}</GqlContext.Provider>
}

type SwrConfigValue = ComponentProps<typeof SWRConfig>['value']

export function GqlProvider(props: GqlProviderProps) {
  const swrConfig = useMemo<SwrConfigValue>(() => {
    // sets the cache provider
    return { provider: () => new Map() }
  }, [])
  return (
    <SWRConfig value={swrConfig}>
      <GqlProviderWithSwr {...props} />
    </SWRConfig>
  )
}

export function useGqlContext() {
  const context = useContext(GqlContext)
  if (context === undefined) {
    throw new Error('useGqlContext must be used within a GqlProvider')
  }
  return context
}
