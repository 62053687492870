import { ComboboxLabel, ListboxLabel } from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { Size, SizeValue, useSizeScreen } from '../useSizeScreen'

import { UseFormFieldProps } from './useForm'

export type BoxContainerProps = {
  children: ReactNode
  className?: string
} & BoxLabelProps &
  BoxErrorProps

/**
 * renders the label above and error below of a box if values are provided
 */
export function FormFieldWrapper({
  className,
  size,
  required,
  label,
  children,
  as,
  error,
}: BoxContainerProps) {
  return (
    <div className={clsx('flex flex-col', className)}>
      {label != null && <FormLabel as={as} label={label} required={required} size={size} />}
      <div className="relative whitespace-nowrap">{children}</div>
      {error != null && <FormError error={error} size={size} />}
    </div>
  )
}

export type BoxLabelProps = {
  /**
   * if 'Combobox' then will render as headless-ui Combobox.Label
   * if 'Listbox' then will render as headless-ui Listbox.Label
   * if 'label' will render as plain HTML <label> tag
   */
  as: 'label' | 'Combobox' | 'Listbox'
  label: ReactNode
  size: SizeValue
  required: boolean | null
  className?: string
}

/**
 * renders the label of a box, makes use of <ComboboxLabel> and <ListboxLabel>
 * based on "as" prop
 */
export function FormLabel({ as, label, size, required, className }: BoxLabelProps) {
  const Component = as === 'Combobox' ? ComboboxLabel : as === 'Listbox' ? ListboxLabel : 'label'
  if (label == null) return null
  return (
    <Component
      className={clsx('flex text-black-light text-left font-semibold mb-2', className, {
        'text-sm': size === 'small',
        'text-base': size === 'medium',
        'text-lg': size === 'large',
      })}
    >
      {label}
      {required ? ' *' : ''}
    </Component>
  )
}

export type BoxErrorProps = {
  error: UseFormFieldProps<unknown>['error']
  size?: Size
}

/**
 * renders the error of a box, receives the same type as useForm().errors.fieldName
 */
export function FormError({ error, size }: BoxErrorProps) {
  const sizeScreen = useSizeScreen(size)
  if (error == null) {
    return null
  }

  return (
    <div className="ml-1 mt-1 mb-2 flex">
      <span
        className={clsx('text-red-100 text-left', {
          'text-sm': sizeScreen === 'small',
          'text-base': sizeScreen === 'medium',
          'text-lg': sizeScreen === 'large',
        })}
      >
        {error instanceof Error ? error.message : error}
      </span>
    </div>
  )
}
