import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, MutableRefObject, ReactNode } from 'react'

import { getTestId } from '@northvolt/test-utils'

export type BaseModalProps = {
  visible: boolean
  header?: ReactNode
  footer?: ReactNode
  onClose: () => void
  /** if false clicking outside the modal in the greyed out area does not close the modal */
  closeOnBackgroundClick?: boolean
  closeButton?: boolean
  /** A ref to an element that should receive focus first when the Modal opens */
  initialFocus?: MutableRefObject<any>
  className?: string
  children: ReactNode
  testId?: string
}

const placeholderOnClose = () => {
  //
}

export function BaseModal({
  visible,
  header,
  footer,
  onClose,
  closeOnBackgroundClick,
  closeButton,
  className,
  initialFocus,
  children,
  testId,
}: BaseModalProps) {
  let headerElement: ReactNode
  if (header == null) {
    headerElement = null
  } else if (typeof header === 'string') {
    headerElement = <h1 className="text-xl text-black-light font-semibold">{header}</h1>
  } else {
    headerElement = header
  }
  let footerElement: ReactNode
  if (footer == null) {
    footerElement = null
  } else {
    footerElement = footer
  }

  return (
    <Transition appear as="div" show={visible}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        initialFocus={initialFocus}
        onClose={closeOnBackgroundClick === false ? placeholderOnClose : onClose}
        unmount={true}
        {...getTestId(testId)}
      >
        <div className="min-h-screen flex items-center justify-center">
          <TransitionChild
            as="div"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogBackdrop as="div" className="fixed inset-0 bg-black opacity-30" />
          </TransitionChild>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel
              as="div"
              className={clsx('fixed transition-all bg-white shadow-xl rounded-lg p-3', className, {
                'md:w-8/12 xl:w-6/12 w-11/12 md:p-7': className == null,
              })}
            >
              <div className="relative">
                {headerElement && <div className="mb-4">{headerElement}</div>}
                <button
                  className={clsx(
                    'absolute outline-none z-10 h-8 w-8 -top-1 -right-1 flex items-center justify-center rounded-full hover:bg-gray-100 transition active:bg-gray-200 group',
                    {
                      // we still render the close button because the modal needs at least one focusable element
                      'select-none opacity-0 pointer-events-none': closeButton === false,
                    },
                  )}
                  onClick={onClose}
                  type="button"
                  {...getTestId('close-modal')}
                >
                  <FontAwesomeIcon
                    className="h-5 w-5 text-gray-500 group-hover:text-gray-700 active:text-gray-700"
                    icon={faTimes}
                  />
                </button>
              </div>
              <div
                className="overflow-y-auto px-2"
                style={{
                  maxHeight: 'calc(100vh - 200px)',
                }}
              >
                {children}
              </div>
              {footerElement && <div className="mt-4">{footerElement}</div>}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  )
}
