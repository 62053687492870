import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faGrid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Menu as HLMenu,
  MenuButton as HLMenuButton,
  MenuItem as HLMenuItem,
  MenuItems as HLMenuItems,
  Transition,
} from '@headlessui/react'
import { Fragment } from 'react'

import { HeaderTheme } from './types'

type MenuOptionProps = {
  title: string
  subtitle?: string
  icon: IconProp
}

function MenuItem({ title, subtitle, icon }: MenuOptionProps) {
  return (
    <HLMenuItem>
      <button className={'group flex rounded items-center p-2 w-full hover:bg-gray-200'}>
        <div className="mr-3 rounded-full h-7 w-7 flex items-center justify-center text-white bg-NvTeal-300">
          <FontAwesomeIcon className="h-3.5 w-3.5 text-white" icon={icon} />
        </div>
        <div className="text-left font-semibold text-black">
          {title}
          {subtitle ? <div className="text-left text-sm text-gray-800">{subtitle}</div> : null}
        </div>
      </button>
    </HLMenuItem>
  )
}

type AppOptionProps = {
  url: string
} & MenuOptionProps

function AppOption({ url, ...rest }: AppOptionProps) {
  return (
    <a href={url}>
      <MenuItem {...rest} />
    </a>
  )
}

export type AppsDropDownProps = {
  /**
   * Apps to display as links in the apps dropdown.
   * If empty is provided, no apps dropdown will be shown.
   */
  apps: AppOptionProps[]

  theme: HeaderTheme
}

export function AppsDropDown({ theme, apps }: AppsDropDownProps) {
  return (
    <HLMenu as="div" className="relative inline-block text-left align-middle">
      <HLMenuButton className="mr-1 p-2 group flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <FontAwesomeIcon
          className={`${
            theme === 'light'
              ? 'text-gray-500 group-hover:text-gray-600'
              : 'text-gray-400 group-hover:text-gray-300'
          } h-7 w-7 transition`}
          icon={faGrid}
        />
      </HLMenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HLMenuItems className="z-30 p-3 absolute right-0 w-52 my-5 origin-top-right divide-y divide-gray-100 bg-white rounded-lg shadow-lg border border-solid border-black border-opacity-5 focus:outline-none">
          {apps.map((app) => (
            <AppOption key={app.url} {...app} />
          ))}
        </HLMenuItems>
      </Transition>
    </HLMenu>
  )
}
